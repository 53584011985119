@import "../../../src/theme/spacing.scss";
@import "../../../src/theme/fonts.scss";
@import "../../../src/theme/colors.scss";

.closeButtonMobile {
  position: absolute;
  top: var(--large-spacing);
  right: 15px;
  background: none;
  border: none;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $overlay-color;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.content {
  background-color: $white-color;
  width: 680px;
  max-height: 100%;
  overflow: auto;
  position: relative;
  padding: var(--extra-large-spacing) var(--extra-large-spacing) var(--extra-large-spacing) 24px;
  border-radius: 10px;
}

.closeButton {
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.title {
  font-family: $primary-font;
  font-weight: $font-weight-semi-bold;
  position: absolute;
  font-size: 18px;
 
}

.imageStyle {
  margin-top: var(--double-extra-large-spacing);
}

.backGroundContent {
  background-color: $white-color;
}

.container{
  display: flex;
  flex-direction: row;
}
