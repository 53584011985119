@import "../../theme/colors.scss";
@import "../../theme/spacing.scss";
@import "../../theme/fonts.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: var(--small-spacing);
}

.datePickerContainer {
  position: relative;
  display: flex;
  width: 100%;
}

.datePickerContainer:focus .floatingLabel {
  top: 0;
  z-index: 1;
}

.errorMessage {
  font-family: $primary-font;
  color: $error-color;
  font-weight: $font-weight-normal;
  font-size: 12px;
  width: 100%;
  text-align: start;
}
.datePickerContainer.error .datePickerField {
  border-color: $error-color;
}

.floatingLabel {
  font-family: $primary-font;
  font-size: 14px;
  font-weight: $font-weight-medium;
  position: absolute;
  top: 50%;

  [dir='ltr'] & {
    left: 15px;
  }

  [dir='rtl'] & {
    right: 15px;
  }

  transform: translateY(-50%);
  background-color: $white-color;
  transition: all 0.3s;
  cursor: pointer;
  padding: 0 5px;
  text-align: center;
  color: $disabled-color;
}

.floatingLabel.active {
  top: 0;
}

.floatingLabel.focused {
  color: $blue-color;
}

.datePickerContainer.error .floatingLabel {
  color: $error-color;
}

.datePickerField {
  font-family: $primary-font;
  color: $primary-color;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  border: 1px solid $gray-color;
  border-radius: 10px;
  outline: none;
  padding: var(--large-spacing) var(--large-spacing) var(--large-spacing) var( --large-spacing);
  background-color: transparent;


  &:focus {
    border-color: $blue-color;
  }
}

input::placeholder {
  font-family: $primary-font;
  color: $disabled-color;
  font-size: 14px;
  font-weight: 500;
   opacity: 0;
}

.datePickerField:focus::placeholder {
  opacity: 1;
}