@import "../../../src/theme/colors.scss";
@import "../../../src/theme/fonts.scss";
@import "../../../src/theme/spacing.scss";

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1000;
}

.modalContent {
  display: flex;
  flex: 1;
  background-color: $white-color;
  padding: 20px;
  align-items: center;
  flex-direction: column;

}

.closeIcon {
  &:hover {
    cursor: pointer;
  }
}

.modalTitle {
  font-family: $secondary-font;
  font-weight: $font-weight-bold;
  font-size: 24px;
}

.menuList {
  width: 100%;
  max-width: 500px;
  display: flex;
  padding-top: 20px;
  gap: 10px;
  flex-direction: column;
}

.languageBox {
  width: 34px;
  height: 34px;
  font-family: $primary-font;
  font-weight: $font-weight-semi-bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1;
  border-radius: 5px 0px 0px 0px;
  border: 1px solid #E0E0E0;

  border-radius: 5px;

  &:hover {
    cursor: pointer;

  }
}

.logoutIconContainer {
  width: 34px;
  height: 34px;
  border-radius: 5px;
  border: 1px solid #E0E0E0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoutIconStyle {
  width: --var(--large-spacing);
  height: --var(--large-spacing);
  font-family: $primary-font;
  font-weight: $font-weight-semi-bold;
  text-align: center;
  font-size: 1;

  &:hover {
    cursor: pointer;

  }

  [dir="rtl"] & {
    rotate: 180deg;
  }
}

.iconStyle {
  [dir='rtl'] & {
    rotate: 180deg;
  }

}