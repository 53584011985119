@import "../../theme/fonts.scss";
@import "../../../src/theme/spacing.scss";
@import "../../../src/theme/colors.scss";

.mainLayout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-width: 100%;
  border: 1px solid #ffffff;
  min-height: 100%;
  padding-bottom: 130px;
  padding-top: var(--extra-large-spacing);
}

.mainLayoutWithAlert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-width: 100%;
  border: 1px solid #ffffff;
  min-height: 100%;
  padding-bottom: var(--extra-extra-large-spacing);
  padding-top: var(--extra-large-spacing);
}

.mainLayoutContract {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-width: 100%;
  border: 1px solid #ffffff;
  min-height: 100%;
}

.topMenu {
  display: flex;
  width: 100%;
  padding-bottom: var(--medium-spacing);
}

.backContainer {
  display: flex;
  flex-direction: row;
  gap: var(--medium-spacing);

  &:hover {
    cursor: pointer;
  }
}

.backText {
  font-family: $primary-font;
  font-size: 14px;
  font-weight: 500;
  color: $primary-color;
}

.contentLayout {
  display: flex;
  width: 520px;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
}

.boxLayout {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  border: 1px solid #d5d6d9;
  padding: 40px;
  position: relative;
  border-radius: 10px;
}

.boxLayoutContract {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  border: 1px solid #d5d6d9;
  padding: 20px 40px 0px 40px;
  position: relative;
  border-radius: 10px;
}

.scrollableContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mainTitle {
  width: 100%;
  font-family: $secondary-font;
  font-size: 24px;
  font-weight: 700;
  text-align: start;
  color: #2d2d2d;
}

.subtitle {
  width: 100%;
  font-family: $primary-font;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: start;
  padding-top: var(--medium-spacing);
  white-space: break-spaces;
}

.footer {
  display: flex;
  border: 1px solid #ffffff;
  text-align: center;
  justify-content: center;
  z-index: 3;
  width: 100%;
  background-color: #ffffff;
}

.footerInner {
  display: flex;
  align-items: center;
  min-width: 250px;
  border: 1px solid #ffffff;
}

.scrollableContent::-webkit-scrollbar {
  width: 5px;
  height: 85px;
  border-radius: 9px;
}

.scrollableContent::-webkit-scrollbar-thumb {
  background-color: #c0c1c6;

  border-radius: 6px;
}

.scrollableContent::-webkit-scrollbar-track {
  background-color: $white-color;
  /* color of the track */
}

.bottomMenu {
  display: flex;
  align-items: start;
  width: 100%;
  padding-top: var(--medium-spacing);
  gap: var(--large-spacing);
}

.menuStyle {
  font-style: $primary-font;
  font-weight: 500;
  font-size: 14px;
  color: $primary-color;

  &:hover {
    cursor: pointer;
  }
}

.contactSupport {
  display: flex;
  width: 100%;
  align-items: start;
  flex-direction: column;
  margin-top: 15px;

  span,
  a {
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
  }
}

@media screen and (max-width: 700px) {
  .mainLayout {
    align-items: normal;
    justify-content: normal;
    padding-top: 0;
    border: none;
  }

  .mainLayoutWithAlert {
    align-items: normal;
    justify-content: normal;
    margin-top: 20px;
  }

  .mainLayoutContract {
    align-items: normal;
    justify-content: normal;
    padding-top: 0;
    border: none;
  }

  .scrollableContent {
    min-height: 87%;
  }

  .contentLayout {
    width: 100%;
  }

  .boxLayout {
    padding: var(--large-spacing);
    border: 0;
  }

  .boxLayoutContract {
    padding: 0px;
    border: 0;
  }

  .footer {
    position: fixed;
    bottom: 0;
  }

  .footerInner {
    width: 100%;
    margin: 20px;
  }

  .stepInfo {
    text-align: start;
  }
}

.stepInfo {
  width: 100%;
  font-family: $primary-font;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: $disabled-color;
  text-align: start;
  gap: var(--small-spacing);
  display: flex;
  flex-direction: row;
}

.stepNumber {
  font-weight: bold;
  color: #2d2d2d;
}

.hr {
  width: 100%;
  height: 3px;
  margin: var(--medium2-spacing) 0 var(--large-spacing);
  border: none;
  border-radius: 2px;
  transition: width 0.2s ease-in;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--large2-spacing);
}

.stepTitle {
  width: 100%;
  font-family: $secondary-font;
  font-size: 18px;
  font-weight: 700;
  text-align: start;
  color: #2d2d2d;
}

.iconStyle {
  [dir="rtl"] & {
    rotate: 180deg;
  }
}

.numStep {
  display: flex;
  flex-direction: row;
}

.optionalBanner {
  margin-bottom: var(--small-spacing);
}