@import "../../theme/spacing.scss";

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: var(--large-spacing);
  gap: var(--medium-spacing);
  width: 100%;
}
