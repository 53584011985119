@import "../../../../src/theme/colors.scss";
@import "../../../../src/theme/spacing.scss";
@import "../../../../src/theme/fonts.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: var(--large-spacing);
}

.customSelectContainer {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.dropdownButton {
  font-family: $primary-font;
  font-size: 14px;
  position: relative;
  color: $primary-color;
  cursor: pointer;
  border: 1px solid $gray-color;
  border-radius: 10px;
  outline: none;
  padding: 20px;
  background-color: transparent;
  text-align: end;

  &:focus {
    border-color: $blue-color;
  }
}

.dropdownButton.disabled {
  cursor: default;
}

.dropdownButton.active {
  border-color: $blue-color;
}

.dropdownButton.error {
  border-color: $error-color;
}

.floatingLabel {
  font-family: inherit;
  font-size: inherit;
  font-weight: $font-weight-medium;
  position: absolute;
  top: 50%;


  [dir='ltr'] & {
    left: 15px;
  }

  [dir='rtl'] & {
    right: 15px;
  }

  transform: translateY(-50%);
  background-color: $white-color;
  transition: all 0.3s;
  cursor: pointer;
  padding: 0 5px;
  text-align: center;
  color: $disabled-color;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 420px;
}

.floatingLabel.disabled {
  cursor: default;
}

.dropdownButton.active .floatingLabel {
  top: 0;
  color: $blue-color;
}

.floatingLabel.active {
  top: 0;
}

.floatingLabel.error {
  color: $error-color;
}

.innerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--medium2-spacing);
}

.dropdownValue {
  border: 1px solid $primary-color;
  border-radius: 100px;
  padding: 3px 12px;
  text-align: center;
  display: inline-flex;
  margin: 6px 8px 6px 0;

  p {
    margin: 0 var(--medium-spacing) 0 0;
    font-size: 12px;
    color: $primary-color;
  }

  img {
    width: 9px;
    height: 9px;
    display: block;
    margin-top: 3px;
    background: #F4F4F4;
    padding: 2px;

    [dir='rtl'] & {
      margin-right: 10px;
    }

  }
}

.dropdownValue.disabled {
  cursor: not-allowed;
  background-color: rgb(192, 193, 198, 0.2);
  border: 1px solid #F4F4F4;
}

.dropdownValue.disabled {
  p {
    opacity: 50%;
  }
}

.errorMessage {
  color: $error-color;
  font-size: 12px;
  width: 100%;
  text-align: left;
}

.customSelectContainer.error .selectField {
  border-color: $error-color;
}

.arrowIcon {
  cursor: pointer;
  display: inline-block;
}

.arrowIcon.disabled {
  opacity: 50%;
  cursor: default;
}

.dropdownContent {
  width: 100%;
  background-color: $white-color;
  box-shadow: 0px 4px 14px 0px #BBBBBB40;
  border-radius: 10px;
  cursor: pointer;
  text-align: start;
  padding: var(--medium-spacing) 0;
  position: absolute;
  z-index: 10;
  top: 100%;
  max-height: 190px;
  ;
  overflow-y: scroll;
}

.optionsContainer {
  display: block;
  max-width: 420px;
  text-align: start;
}

.dropdownItem {
  font-family: $primary-font;
  font-size: 14px;
  font-weight: $font-weight-normal;
  transition: all 0.3s;
  padding: var(--medium-spacing) var(--large-spacing);
}

.dropdownItem:hover,
.dropdownItem.active {
  background-color: rgba(117, 149, 245, 0.1);
}

.dropdownItem.disabled {
  cursor: not-allowed;
}

.selectedOptionContainer {
  position: relative;
}

.selectedOptionContainer img {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.floatingLabel,
.arrowIcon,
.dropdownValue {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media screen and (max-width: 1024px) {
  .optionsContainer {
    max-width: 100%;
  }
}