@import "../../../../theme/fonts.scss";

.container {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 15px;
}


.innerContainer1 {
  display: flex;
  flex-direction: column;
  min-width: 38px;
}

.innerContainer2 {
  display: flex;
  flex-direction: column;
}

.title {
  font-family: $primary-font;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: start;
}

.subTitle {
  font-family: $primary-font;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: start;
  color: #00000066;
}

.verticalLine {
  width: 1px;
  height: 42px;
  background-color: #d5d6d9;

  [dir='rtl'] & {
    margin: 5px 20px 5px 5px;
  }

  [dir='ltr'] & {
    margin: 5px 5px 5px 20px;
  }
}