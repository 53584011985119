@import "../../theme/spacing.scss";
@import "../../theme/fonts.scss";
@import "../../theme/colors.scss";

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid $error-color;
    padding: var(--medium-spacing);
    border-radius: 10px;
    margin-bottom: var(--large-spacing);
}

.title {
    font-family: $primary-font;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: start;
    color: $error-color;
    width: 100%;
}

.subTitleContainer {
    display: flex;
    flex-direction: column;
    position: relative;
}

.subTitle {
    font-family: $primary-font;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: start;
    color: $primary-color;
    margin-top: var(--medium-spacing);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

}

.viewMore {
    font-family: $primary-font;
    font-size: 14px;
    font-weight: 500;
    text-align: start;
    color: $blue-color;
    text-decoration: underline;
    padding-top: var(--medium-spacing);


    &:hover {
        cursor: pointer;
    }
}

.description {
    font-family: $primary-font;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: start;
    color: $primary-color;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

}


.containerWithShadow { 
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid $error-color;
    padding: var(--medium-spacing);
    border-radius: 10px;
    margin-bottom: var(--large-spacing);
    box-shadow: 0px 4px 14px 0px #BBBBBB40;

}