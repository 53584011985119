@import "../../../../theme/fonts.scss";

.primaryButton {
  width: 100%;
  padding: 15px;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  font-family: $primary-font;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #2b2a2a;
  border-radius: 100px;
  border: "1px  #2b2a2a";

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
  }

  &:disabled:hover {
    opacity: 0.5;
    cursor: unset;
  }
}