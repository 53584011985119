@import "../../../src/theme/colors.scss";
@import "../../../src/theme/spacing.scss";
@import "../../../src/theme/fonts.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: var(--large-spacing);
  justify-content: center;
  align-items: center;
}

.customSelectContainer {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.dropdownButton {
  font-family: $primary-font;
  width:60px;
  font-size: 16px;
  position: relative;
  color: $primary-color;
  cursor: pointer;
  border-radius: var(--medium-spacing);
  font-weight: 600;
  outline: none;
  background-color: transparent;
  text-align: end;
  padding: var(--small-spacing) var(--medium-spacing);

  [dir='rtl'] & {
    direction: ltr;
  }

  &:focus {
    border-color: $blue-color;
  }
}

.dropdownButton.disabled {
  cursor: default;
  opacity: 0.5;
}

.dropdownButton.active {
  border: 1px solid $blue-color;
}

.dropdownButton.error {
  border-color: $error-color;
}

.floatingLabel {
  font-family: inherit;
  font-size: inherit;
  font-weight: $font-weight-medium;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  background-color: $white-color;
  transition: all 0.3s;
  cursor: pointer;
  padding: 0 5px;
  text-align: left;
  color: $disabled-color;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 420px;
}

.floatingLabel.disabled {
  cursor: default;
}

.dropdownButton.active .floatingLabel {
  top: 0;
  color: $blue-color;

}

.floatingLabel.active {
  top: 0;
}

.floatingLabel.error {
  color: $error-color;
}

.innerContent {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap:var(--medium-spacing);
  align-items: center;
}

.dropdownValue {
  margin: 0;
}

.errorMessage {
  color: $error-color;
  font-size: 12px;
  width: 100%;
  text-align: left;
}

.customSelectContainer.error .selectField {
  border-color: $error-color;
}

.arrowIcon {
  margin: 0 0 0 auto;
  cursor: pointer;
}

.arrowIcon.disabled {
  opacity: 50%;
  cursor: default;
}

.dropdownContent {
  min-width: 150px;
  background-color: $white-color;
  box-shadow: 0px 4px 14px 0px #BBBBBB40;
  border-radius: var(--medium-spacing);
  cursor: pointer;
  text-align: start;
  padding: var(--medium-spacing) 0;
  position: absolute;
  z-index: 10;

  [dir='rtl'] & {
    left: 0;
  }

  [dir='ltr'] & {
    right: 0;
  }

  top: var(--double-extra-large-spacing);
  max-height: 300px;
  overflow-y: auto;


}

/* styles.css */
.dropdownContent::-webkit-scrollbar {
  width: 5px;
  height: 85px;
  border-radius: 9px;
  /* width of the scrollbar */
}

.dropdownContent::-webkit-scrollbar-thumb {
  background-color: #C0C1C6;
  /* color of the thumb */
  border-radius: 6px;
  /* roundness of the thumb */
}

.dropdownContent::-webkit-scrollbar-track {
  background-color: $white-color;
  /* color of the track */
}

.dropdownItem {
  font-family: "Inter";
  font-size: 14px;
  font-weight: $font-weight-normal;
  transition: all 0.3s;
  padding: var(--medium-spacing) var(--large-spacing);
}

.dropdownItem:hover,
.dropdownItem.active {
  background-color: rgba(117, 149, 245, 0.1);
}

.selectedOptionContainer {
  position: relative;
}

.selectedOptionContainer img {
  position: absolute;
  top: 50%;

  [dir='ltr'] & {
    right: 20px;
  }

  [dir='rtl'] & {
    left: 20px;
  }

  transform: translateY(-50%);
}

.floatingLabel,
.arrowIcon,
.dropdownValue {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}