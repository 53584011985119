@import "../../theme/spacing.scss";
@import "../../theme/fonts.scss";
@import "../../theme/colors.scss";

.pdfViewer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: var(--extra-large-spacing);
}

.paginationContainer {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: row;
    gap: var(--medium-spacing);
    align-items: center;
    justify-content: center;
    padding-top: var(--medium-spacing);
    background-color: $white-color;

}

.pageNumber {
    font-family: $primary-font;
    font-size: 18px;
    font-weight: $font-weight-medium;
    line-height: 34px;
    text-align: center;
    color: $primary-color;
}



.arrowLeft {
    [dir="ltr"] & {
        rotate: 180deg;
    }

    :hover {
        cursor: pointer;
    }
}

.arrowRight {
    [dir="rtl"] & {
        rotate: 180deg;
    }


    :hover {
        cursor: pointer;
    }
}