@import "../../../../src/theme/colors.scss";
@import "../../../../src/theme/spacing.scss";
@import "../../../../src/theme/fonts.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: var(--small-spacing);
}

.customSelectContainer {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: var(--medium-spacing);
}

.dropdownButton {
  font-family: $primary-font;
  font-size: 14px;
  position: relative;
  color: $primary-color;
  cursor: pointer;
  border: 1px solid $gray-color;
  border-radius: 10px;
  outline: none;
  padding: var(--large-spacing);
  background-color: transparent;
  text-align: end;

  &:focus {
    border-color: $blue-color;
  }
}

.dropdownButton.disabled {
  cursor: default;
  opacity: 0.5;
}

.dropdownButton.active {
  border-color: $blue-color;
}

.dropdownButton.error {
  border-color: $error-color;
}

.floatingLabel {
  font-family: inherit;
  font-size: inherit;
  font-weight: $font-weight-medium;
  position: absolute;
  top: 50%;

  [dir="ltr"] & {
    left: 15px;
  }

  [dir="rtl"] & {
    right: 15px;
  }

  transform: translateY(-60%);
  background-color: $white-color;
  transition: all 0.3s;
  cursor: pointer;
  padding: 0 5px;
  text-align: start;
  color: $disabled-color;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}

.fixedLabel {
  font-family: $primary-font;
  font-size: 14px;
  font-weight: $font-weight-medium;
  background-color: $white-color;
  text-align: start;
  color: $primary-color;
}

.floatingLabel.disabled {
  cursor: default;
}

.dropdownButton.active .floatingLabel {
  top: 0;
  color: $blue-color;
}

.floatingLabel.active {
  top: 0;
}

.floatingLabel.error {
  color: $error-color;
}

.innerContent {
  display: block;
}

.dropdownValue {
  margin: 0;
  position: absolute;
  text-align: start;
  color: $primary-color;

  [dir="ltr"] & {
    left: 20px;
  }

  [dir="rtl"] & {
    right: 20px;
  }
}

.errorMessage {
  color: $error-color;
  font-size: 12px;
  width: 100%;
  text-align: start;
}

.customSelectContainer.error .selectField {
  border-color: $error-color;
}

.arrowIcon {
  margin: 0 0 0 auto;
  cursor: pointer;
}

.arrowIcon.disabled {
  opacity: 50%;
  cursor: default;
}

.dropdownContent {
  width: 100%;
  background-color: $white-color;
  box-shadow: 0px 4px 14px 0px #bbbbbb40;
  border-radius: 10px;
  cursor: pointer;
  text-align: start;
  padding: var(--medium-spacing) 0;
  position: absolute;
  z-index: 10;
  top: 100%;
  max-height: 190px;
}

.dropdownItem {
  font-family: "Inter";
  font-size: 14px;
  font-weight: $font-weight-normal;
  transition: all 0.3s;
  padding: var(--medium-spacing) var(--large-spacing);
}

.dropdownItem:hover,
.dropdownItem.active {
  background-color: rgba(117, 149, 245, 0.1);
}

.selectedOptionContainer {
  position: relative;
}

.selectedOptionContainer img {
  position: absolute;
  top: 50%;

  [dir="ltr"] & {
    right: 20px;
  }

  [dir="rtl"] & {
    left: 20px;
  }

  transform: translateY(-50%);
}

.floatingLabel,
.arrowIcon,
.dropdownValue {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.placeholder {
  font-family: $primary-font;
  font-size: 14px;
  color: $disabled-color;
  font-weight: $font-weight-medium;

  margin: 0;
  position: absolute;

  [dir="ltr"] & {
    left: 20px;
  }

  [dir="rtl"] & {
    right: 20px;
  }
}
