@import "../../theme/fonts.scss";
@import "../../theme/colors.scss";
@import "../../theme/spacing.scss";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.wrapper {
  width: 100%;
  border: 1px solid $outline-color;
  background-color: $white-color;
  border-radius: var(--medium-spacing);
  margin-bottom: var(--large-spacing);
   box-shadow: 0px 4px 14px 0px #9696961A;
  overflow: hidden;
  padding: var(--large-spacing) var(--large-spacing) var(--medium-spacing) var(--large-spacing);
}

.accordionTitleContainer {
  width: 100%;
  padding: 0 0 var(--medium-spacing) 0;
  display: flex;
  justify-content: space-between;
  font-family: $primary-font;
  font-weight: $font-weight-semi-bold;
  font-size: var(--medium2-spacing);
  background: transparent;
  cursor: pointer;

}

.headerActions {
  display: flex;
  align-items: center;
  gap: var(--medium2-spacing);
}

.editAction {
  display: flex;
  align-items: center;
  gap: var(--small-spacing);

}

.stepTitleContent {
  text-align: left;
  flex: 1;
}

.editButton {
  font-size: var(--medium2-spacing);
  font-family: $primary-font;
  font-weight: $font-weight-normal;
  border: none;
  background-color: transparent;
  color: $blue-color;
}



.arrow {

  transition: .5s ease-in-out;

  &.active {
    transform: rotate(180deg);
    /* Corrected line */
  }
}

.hr {
  border: 1px solid #D5D6D9;
  margin: 0 0 var(--medium-spacing) 0;
}

.fieldLabel {
  font-family: $primary-font;
  font-weight: $font-weight-normal;
  font-size: var(--medium2-spacing);
  color: $disabled-color;
  display: block;
  margin-bottom: var(--small-spacing);
}

.answerContainer {
  text-align: start;
  transition: height .3s ease-in-out;

}

.fieldValue {
  font-family: $primary-font;
  font-weight: $font-weight-medium;
  font-size: var(--medium2-spacing);
  color: $primary-color;
  display: block;
  margin-bottom: var(--medium-spacing);
}

.noPaddingAccordionTitleContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: $primary-font;
  font-weight: $font-weight-semi-bold;
  font-size: var(--medium2-spacing);
  background: transparent;
  cursor: pointer;

}

.missingFieldsText {
  font-family: $primary-font;
  font-weight: $font-weight-normal;
  font-size: 12px; 
  color: $error-color;   
  text-align: left;
  padding: var(--small-spacing) 0 var(--medium-spacing) 0;

 
[dir="rtl"] & {
  text-align: right;
  }
}

.noPaddingBottomwrapper {
  width: 100%;
  border: 1px solid $outline-color;
  background-color: $white-color;
  border-radius: var(--medium-spacing);
  margin-bottom: var(--large-spacing);
   box-shadow: 0px 4px 14px 0px #9696961A;
  overflow: hidden;
  padding: var(--large-spacing) var(--large-spacing) 0 var(--large-spacing);
}

.imageBox {
width: 58px;
height: 58px;
border: 1px solid  #C2C2C5;
border-radius: var(--small-spacing); 
overflow: hidden;
}



.imageBox  img{
width: 100%;
height: 100%;
object-fit: contain;

}

.documentSection {
 
  margin-top: 20px;
}
 
.documentItem{
  width: 100%;
  border-radius: 10px;
  border:  1px solid $light-color;
  padding: 12px  12px ;
   margin-bottom: 10px;
}

.documentItemText {
 font-family: $primary-font;
font-size: 14px;
font-weight: $font-weight-normal;
line-height: 22px;
text-align: left;
color:  #191C1F;
margin-left: 4px;
[dir="rtl"] & {
  text-align: right;
  }
}

.accordionTitle {
font-family: $primary-font;
font-size: 14px;
font-weight: $font-weight-semi-bold;
color:  $primary-color;

}
