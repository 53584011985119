@import "../../theme/spacing.scss";
@import "../../theme/colors.scss";
@import "../../theme/fonts.scss";

.container {
  display: flex;
  flex-direction: row;
  gap: var(--small-spacing);
  min-width: 100%;
  padding-top: var(--large-spacing);
  padding-bottom: var(--large-spacing);
  align-items: center;
}

.estimatedTime {
  font-family: $primary-font;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: $primary-color;
}

.timeLineContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: var(--large-spacing);
}

.labelBrokerage {
  font-family: $primary-font;
  font-size: 16px;
  font-weight: 600;
  text-align: start;
  color: $primary-color;

}

.fieldsContainer {
  display: flex;
  flex-direction: column;
  gap: var(--large-spacing);
  padding-bottom: var(--large-spacing);
  box-shadow: 0px 4px 16px 0px #9696961E;
  border-radius: 10px;
  padding: var(--large-spacing);
  margin-bottom: var(--large-spacing);
}

.ctaContainer {
  display: flex;
  flex-direction: column;
  gap: var(--small-spacing);
  min-width: 100%;
  padding-bottom: var(--large-spacing);
  align-items: center;
}

.termsContainer {
  display: flex;
  flex-direction: row;
  gap: var(--medium-spacing);
  min-width: 100%;
  padding-bottom: var(--medium-spacing);
  align-items: start;
}

.termsText1 {
  font-family: $primary-font;
  font-size: 14px;
  font-weight: normal;
  text-align: start;
  color: $primary-color;

}

.termsText2 {
  font-family: $primary-font;
  font-size: 14px;
  font-weight: $font-weight-medium;
  text-align: start;
  color: $blue-color;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.reviewText {
  font-family: $primary-font;
  font-size: 14px;
  font-weight: $font-weight-medium;
  text-align: start;
  color: $primary-color;
  text-decoration: underline;
  padding-top: var(--medium-spacing);

  &:hover {
    cursor: pointer;
  }
}

.checkBox {


  &:hover {
    cursor: pointer;
  }
}