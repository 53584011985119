@import "../../theme/spacing.scss";

.contractIframe {
  border: none;
  width: 100%;
  height: 600px;
  margin-top: var(--large-spacing);
}


@media screen and (max-width: 700px) {
.contractIframe {
  margin-top: 0px;
}
}