.spinnerContainer {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(55, 55, 55, 0.3);
   z-index: 1000;
   display: flex;
   justify-content: center;
    align-items: center;
}

.spinner {
   width: 56px;
   height: 56px;
   border-radius: 50%;
   background: radial-gradient(farthest-side,#000000 94%,#0000) top/9px 9px no-repeat,
          conic-gradient(#0000 30%,#000000);
   -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 0);
   animation: spinner 0.8s infinite linear;
}

@keyframes spinner {
   100% {
      transform: rotate(1turn);
   }
}