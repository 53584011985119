@import "../../../../theme/spacing.scss";
@import "../../../../theme/fonts.scss";
@import "../../../../theme/colors.scss";



.additionalDocumentButton {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    border: 1px solid $outline-color;
    font-size: $primary-font;
    font-size: 14px;
    font-weight: $font-weight-medium;
    color: $primary-color;
    text-align: start;
    border-radius: 10px;
    padding: var(--medium2-spacing) var(--large-spacing);
    gap: var(--medium-spacing);

    &:hover {
        cursor: pointer;
    }
}

.fileUploadInput {
    font-size: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: none;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.errorMessage {
    font-family: $primary-font;
    color: $error-color;
    font-weight: $font-weight-normal;
    font-size: 12px;
    width: 100%;
    text-align: start;
}