@import "../../../src/theme/spacing.scss";
@import "../../../src/theme/colors.scss";
@import "../../../src/theme/fonts.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: var(--large-spacing);
  gap: var(--medium2-spacing);
}

.errorText {
  color: $error-color;
  font-family: $primary-font;
}
.bigContainer {
  margin-top: var(--large-spacing);
}
.largeContainer {
  margin-top:var(--double-extra-large-spacing)
}