.darkBackground {
  background-color: rgba(55, 55, 55, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.cameraModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0;
  max-width: 650px;
  height: auto;
  width: 100%;
  z-index: 10;

  .camera {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  }
}

.previewImage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04); 

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.previewActions {
  position: absolute;
  left: 50%;
  bottom: 20px;
  cursor: pointer;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
    
  img {
    cursor: pointer;
    width: 30px;
    height: 30px;
  }
}

.cameraCloseIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.3);
  width: 25px;
  height: 25px;
  padding: 4px;
  border-radius: 100%;
  cursor: pointer;
  z-index: 999;
}

.cameraButton {
  width: 50px;
  height: 50px;
  left: 50%;
  bottom: 20px;
  position: absolute;
  cursor: pointer;
  transform: translateX(-50%);
}

.cameraCircle {
  position: absolute;
  top: 12%;
  left: 12%;
  bottom: 12%;
  right: 12%;
  border-radius: 100%;
  background-color: #ffffff;
  opacity: 0;
}

.cameraRing {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  border: 4px solid #ffffff;
  opacity: 0.8;
}

.cameraButton .cameraCircle, .cameraButton .cameraRing {
  transition: all 0.25s;
}

.cameraButton:hover .cameraCircle {
  opacity: 1;
}

.cameraButton:active .cameraRing {
  opacity: 1;
}

.cameraButton:active .cameraCircle {
  opacity: 0.4;
}

@media screen and (max-width: 700px) {
  .cameraModal {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
}