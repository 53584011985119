@import "../../theme/fonts.scss";

.menuBox {
  width: 100%;
  display: flex;
  border-radius: 10px;
  background-color: white;
  align-items: center;
  border: 1px solid #eaeaea;
  height: 56px;
  box-shadow: 0px 4px 14px 0px #9696961a;

  &:hover {
    cursor: pointer;
  }
}

.menuBoxContent {
  display: flex;
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: space-between;
  align-items: center;
}

.menuItemLabel {
  font-family: $primary-font;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}