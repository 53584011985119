@import "../../../src/theme/spacing.scss";
@import "../../../src/theme/colors.scss";
@import "../../../src/theme/fonts.scss";

.contactSupportPage {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: var(--medium-spacing);

  span {
    font-size: 14px;
    color: $primary-color;
  }
}

.supportSection {
  width: 100%;
  text-align: start;
}

.supportSection:not(:last-child) {
  margin-bottom: 12px;
}

.phoneNumber {
  display: block;
  width: 100%;
  margin-top: 12px;
  padding: 16px 12px;
  border-radius: 10px;
  box-shadow: 0 4px 14px 0 rgba(150, 150, 150, 0.1);
  border: solid 1px #eaeaea;
  font-size: 16px;
  color: $primary-color;
  font-weight: $font-weight-semi-bold;
}

p {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
}