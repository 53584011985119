@import "../../theme/fonts.scss";
@import "../../theme/spacing.scss";

.mainContainer {
  display: flex;
  flex: 1;
  padding-top: 20px;
  flex-direction: column;
  gap: 20px;
  min-height: 100%;
  align-items: center;
  min-width: 100%;
  position: relative;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-bottom: 20px;
}

.bottomContainer {
  display: flex;
  padding: var(--medium-spacing) var(--medium2-spacing);
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  gap: 10px;
  width: 100%;
  box-shadow: 0px 4px 14px 0px #9696961A;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.bottomInnerContainer {
  display: flex;
  flex-direction: column;

}

.mainTitle {
  font-family: $primary-font;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: start;
  color: #2d2d2d;
}

.subTitle {
  font-family: $primary-font;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: start;
  color: #2d2d2d;
}

.iconStyle {
  [dir='rtl'] & {
    rotate: 180deg;
  }

}