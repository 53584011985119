@import "../../../../theme/spacing.scss";
@import "../../../../theme/fonts.scss";
@import "../../../../theme/colors.scss";

.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    border: 1px solid $outline-color;
    box-shadow: 0px 4px 14px 0px #9696961A;
    border-radius: 10px;

    &:hover {
        cursor: pointer;
    }
}

.container.active {
    border: 1px solid $blue-color;
}

.innerContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.title {
    margin-top: var(--medium-spacing);
    font-family: $primary-font;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: start;
    color: $primary-color;
    padding-left: var(--medium-spacing);

    [dir="rtl"] & {
        padding-right: var(--medium-spacing);
    }
}

.badgeContainer {
    align-self: flex-start;
    background-color: $approved-color;
    padding: var(--small-spacing) var(--medium-spacing);
    font-family: $primary-font;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: $white-color;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;

    [dir="rtl"] & {
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.subtitle {
    font-family: $primary-font;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: start;
    color: $primary-color;
    margin-top: var(--medium-spacing);
    padding: 0 var(--medium-spacing) var(--medium-spacing);
}