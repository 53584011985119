@import "../../../src/theme/colors.scss";
@import "../../../src/theme/spacing.scss";
@import "../../../src/theme/fonts.scss";

.container {
    width: 68px;
    height: 24px;
    border-radius: 10px;
}

.title {
    font-family: $primary-font;
    font-weight: $font-weight-normal;
    font-size: 12px;
    color: #4B4B4B;
}