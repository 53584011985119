$primary-color: #2d2d2d;
$outline-color: #eaeaea;
$error-color: #e94a66;
$approved-color: #1bbaa4;
$light-color: #f4f4f4;
$disabled-color: #c0c1c6;
$white-color: #ffffff;
$blue-color: #7595f5;
$light-green: #d6f5f0;
$gray-color: #d5d6d9;
$nav-gray-color: #d8d8d8;
$disabled-color-v2: #9c9c9c;
$fixed-label-color: #919191;
$overlay-color:#373737B2;
