@import "../../../../../src/theme/colors.scss";
@import "../../../../../src/theme/spacing.scss";
@import "../../../../../src/theme/fonts.scss";

.verificationStepsContainer {
  display: flex;
  flex-direction: column;
  gap: var(--medium-spacing);
}

.stepContainer {
  display: flex;
  align-items: start;
  flex-direction: row;
  border: 1px solid $outline-color;
  border-radius: 10px;
  padding: var(--medium-spacing);
  gap: var(--medium-spacing);
  box-shadow: 0px 4px 14px 0px #9696961A;
  position: relative;
}

.stepOrder {
  padding: var(--small-spacing);
  background-color: $outline-color;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  font-family: $primary-font;
  font-size: 12px;
  font-weight: $font-weight-semi-bold;
  text-align: center;
}

.doneIcon {
  max-width: 100%;
  height: auto;
  width: 28px;
}

.stepContent {
  display: flex;
  align-items: start;
  justify-content: start;
  text-align: left;
  gap: var(--medium-spacing);
}

.stepTitle {
  font-family: $primary-font;
  font-size: 16px;
  font-weight: $font-weight-semi-bold;
  margin-bottom: var(--medium-spacing);
  text-align: start;
}

.stepSubTitle {
  font-family: $primary-font;
  font-size: 14px;
  font-weight: $font-weight-medium;
}

.stepOrder.disabled,
.stepTitle.disabled,
.stepSubTitle.disabled {
  color: $disabled-color-v2;
}

.tooltipContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  [dir='rtl'] & {
    left: 10px;
  }

  [dir='ltr'] & {
    right: 10px;
  }
}

.tooltipContainer.disabled {
  opacity: 0.5;
}

.fileUploadInput {
  font-size: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: none;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.errorMessage {
  font-family: $primary-font;
  color: $error-color;
  font-weight: $font-weight-normal;
  font-size: 12px;
  width: 100%;
  text-align: start;
}