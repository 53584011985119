.footerContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
}

@media screen and (max-width: 700px) {
    .footerContainer {
        width: 100vw;
    }

}