@import "../../theme/spacing.scss";
@import "../../theme/fonts.scss";
@import "../../theme/colors.scss";

.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: var(--medium-spacing);
}

.documentsNeededContainer {
    display: flex;
    gap: var(--medium-spacing);
    flex-direction: column;
}

.documentNeedTitle {
    font-size: $primary-font;
    font-weight: $font-weight-semi-bold;
    font-size: 16px;
    color: $primary-color;
    text-align: left;
}





.note {
    font-size: $primary-font;
    font-size: 14px;
    font-weight: $font-weight-normal;
    text-align: start;
    color: #00000066;
}

.footerContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: var(--medium-spacing);
}

.documentsAdditionalContainer {
    display: flex;
    gap: var(--medium-spacing);
    flex-direction: column;
    margin-top: var(--large-spacing);
}

.skipForNow {
    font-size: $primary-font;
    font-size: 14px;
    font-weight: $font-weight-medium;
    color: $primary-color;
    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }
}