@import "../../../src/theme/spacing.scss";
@import "../../../src/theme/fonts.scss";
@import "../../../src/theme/colors.scss";

.closeButtonMobile {
  position: absolute;
  top: var(--large-spacing);
  right: 15px;
  background: none;
  border: none;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(55, 55, 55, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.content {
  background-color: $white-color;
  width: 680px;
  max-height: 100%;
  overflow: auto;
  position: relative;
  padding: var(--extra-large-spacing) var(--extra-large-spacing) 0px var(--large-spacing);
  border-radius: 10px;
}

.closeButton {
  position: absolute;
  right: var(--extra-large-spacing);
  background: none;
  border: none;
  cursor: pointer;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.title {
  font-family: $primary-font;
  font-weight: $font-weight-semi-bold;
  font-size: 18px;
}

.subTitle {
  font-family: $primary-font;
  font-weight: $font-weight-normal;
  font-size: 14px;
}

.imageStyle {
  margin-top: var(--extra-large-spacing);
}

.backGroundContent {
  background-color: $white-color;
}

.container {
  margin-top: var(--double-extra-large-spacing);
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: var(--medium2-spacing);
}

.mobileContainer {
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: var(--medium2-spacing) var(--large-spacing) 0px var(--large-spacing);
  border-radius: 10px;
}


@media screen and (max-width: 700px) {
  .overlay {
    padding: var(--large-spacing)
  }

  .subTitle {
    margin-top: var(--medium-spacing);
  }
}