@import "../../../src/theme/colors.scss";
@import "../../../src/theme/spacing.scss";

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $white-color;
  border-bottom: solid 1px $nav-gray-color;
  padding: 10px 40px 10px 40px;
  height: 73px;
}

.items {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &>* {
    margin-right: var(--extra-medium-spacing)
  }
}

.selectDiv {
  min-width: 60px;
}

.logo {
  &:hover {
    cursor: pointer;
  }
}