@import "../../../../src/theme/colors.scss";
@import "../../../../src/theme/spacing.scss";
@import "../../../../src/theme/fonts.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: var(--medium2-spacing);
}

.inputContainer {
  position: relative;
  display: flex;
  width: 100%;

}

.errorMessage {
  font-family: $primary-font;
  color: $error-color;
  font-weight: $font-weight-normal;
  font-size: 12px;
  width: 100%;
   text-align: start;
}

.inputContainer.error .inputField {
  border-color: $error-color;
}

.floatingLabel {
  font-family: $primary-font;
  font-weight: $font-weight-medium;
  font-size: 14px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: $white-color;
  transition: all 0.3s;
  cursor: pointer;
  padding: 0 5px;
  text-align: center;
  color: $disabled-color;
  max-width: 90%;
}

.floatingLabel.active {
  top: 0;
}

.floatingLabel.focused {
  color: $blue-color;
}

.inputIcon {
  position: absolute;
  top: 50%;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}

.inputContent {
  font-family: $primary-font;
  font-weight: $font-weight-medium;
  color: $disabled-color;
  position: absolute;
  top: 50%;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  
}

.inputContainer.error .floatingLabel {
  color: $error-color;
}

.inputField {
  font-family: "Inter";
  font-size: 14px;
  color: $primary-color;
  cursor: pointer;
  width: 100%;
  border: 1px solid $gray-color;
  border-radius: 10px;
  outline: none;
  padding: var(--large-spacing) 0px var(--large-spacing) 0px;

  &:focus {
    border-color: $blue-color;
  }
   [dir='rtl'] & {
     text-align: right;
  }
}