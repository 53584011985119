@import "../../theme/fonts.scss";
@import "../../theme/spacing.scss";

.container {
  display: flex;
  align-items: center;
  padding: var(--medium2-spacing);
  border-radius: var(--medium-spacing);
  justify-content: center;

}

.alertMessage {
  font-family: $primary-font;
  font-weight: 600;
  flex: 1;
  font-size: 12px;
  text-align: start;
  min-width: 160px;
  padding-left: var(--medium-spacing);

  [dir="rtl"] & {
    padding-right: var(--medium-spacing);
  }
}

