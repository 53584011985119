@import "../../../../src/theme/colors.scss";
@import "../../../../src/theme/spacing.scss";
@import "../../../../src/theme/fonts.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: var(--small-spacing);
  flex: 1;
}

.inputContainer {
  position: relative;
  display: flex;
  width: 100%;
  direction: ltr;
}

.errorMessage {
  font-family: $primary-font;
  color: $error-color;
  font-weight: $font-weight-normal;
  font-size: 12px;
  width: 100%;
  text-align: start;

}

.inputContainer.error .inputField {
  border-color: $error-color;
}

.fixedLabel {
  font-family: $primary-font;
  font-size: 14px;
  font-weight: $font-weight-medium;
  background-color: $white-color;
  text-align: start;
  color: $primary-color;
}

.floatingLabel {
  font-family: $primary-font;
  font-weight: $font-weight-medium;
  font-size: 14px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: $white-color;
  transition: all 0.3s;
  cursor: pointer;
  padding: 0 5px;
  text-align: center;
  color: $disabled-color;
  max-width: 90%;
}

.floatingLabel.active {
  top: 0;
}

.floatingLabel.focused {
  color: $blue-color;
}

.floatingLabel.active.disabled {
  cursor: default;
  z-index: 2;
}

.floatingLabel.emptyDisabled {
  background-color: transparent;
}

.inputIcon {
  position: absolute;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
  padding: 8px 0 8px 10px;
}

.tooltipIcon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 1;
  width: max-content;

  [dir="rtl"] & {
    left: 20px;
  }
}

.inputContent {
  font-family: $primary-font;
  font-size: $font-weight-medium;
  color: $disabled-color;
  position: absolute;
  top: 50%;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}

.inputContainer.error .floatingLabel {
  color: $error-color;
}

.inputField {
  font-family: "Inter";
  font-size: 14px;
  color: $primary-color;
  cursor: pointer;
  width: 100%;
  border: 1px solid $gray-color;
  border-radius: 10px;
  outline: none;
  padding: var(--large-spacing) 0px var(--large-spacing) 0px;
  background-color: transparent;

  &:focus {
    border-color: $blue-color;
  }

  [dir="rtl"] & {
    text-align: right;
  }
}

.inputField::placeholder {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  color: #c0c1c6;
  opacity: 1;

  [dir="rtl"] & {
    text-align: right;
  }
}

.inputField.disabled {
  cursor: default;
  opacity: 0.5;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}