@import "../../theme/spacing.scss";

.mainContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: var(--large-spacing);
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.loader {
  border: 2px solid transparent;
  border-top: 2px solid #04cbab;
  border-bottom: 2px solid #f3f3f3;
  border-left: 2px solid #04cbab;
  border-right: 2px solid #04cbab;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: loader 1s linear infinite;
}
