@font-face {
  font-family: "Inter";
  src: url("./Inter/static/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./Inter/static/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./Inter/static/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Ubuntu";
  src: url("./Ubuntu/Ubuntu-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
body {
  font-family: 'Inter';
}
