@import "../../theme/fonts.scss";
@import "../../theme/spacing.scss";
@import "../../theme/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  width: 100%;
  font-family: $secondary-font;
  font-size: 24px;
  font-weight: $font-weight-bold;
  line-height: 34px;
  text-align: center;
  color: $primary-color;
  overflow: hidden;
  padding-bottom: var(--medium-spacing);
}

.subtitleMessage {
  font-family: $primary-font;
  width: 100%;
  font-size: 14px;
  $font-weight-normal: 400;
  line-height: 22px;
  text-align: center;
  color: #2d2d2d;
}

.imageStyle {
  padding-bottom: var(--medium-spacing);
}

@media screen and (max-width: 700px) {
  .container {
    min-height: 60vh;
  }
}

.loadingContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: var(--medium-spacing);
}

.circle {
  width: var(--medium-spacing);
  height: var(--medium-spacing);
  border-radius: 50%;
  background-color: #fff;
  margin: 0 var(--medium-spacing);;
  animation: fill 3s infinite;
}

.circle:nth-child(1) {
  animation-delay: 0s;
}

.circle:nth-child(2) {
  animation-delay: 1s;
}

.circle:nth-child(3) {
  animation-delay: 2s;
}

@keyframes fill {
  0% {
    background-color: $primary-color;
  }
  100% {
    background-color: $white-color;
  }
}
