@import "../../../src/theme/spacing.scss";
@import "../../../src/theme/fonts.scss";
@import "../../../src/theme/colors.scss";

.closeButtonMobile {
    position: absolute;
    top: var(--large-spacing);
    right: 15px;
    background: none;
    border: none;
    cursor: pointer;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $overlay-color;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: var(--large-spacing) var(--large-spacing) var(--large-spacing) var(--large-spacing);
}

.content {
    background-color: $white-color;
    width: 680px;
    max-height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: var(--extra-large-spacing) var(--extra-large-spacing) var(--extra-large-spacing) var(--extra-large-spacing);
    border-radius: 10px;
}

.closeButton {
    background: none;
    border: none;
    cursor: pointer;
}



.title {
    font-family: $primary-font;
    font-weight: $font-weight-semi-bold;
    font-size: 18px;
}

.header {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.contentText {
    color: $primary-color;
    font-family: $primary-font;
    font-size: 14px;
    text-align: start;
    white-space: pre-line;
}


.container {
    display: flex;
    flex-direction: row;
}

.containerText {
    display: flex;
    flex-direction: column;
    gap: var(--large-spacing);
    margin-top: var(--large-spacing);
    direction: ltr;

}

.contentTextAr {
    color: $primary-color;
    font-family: $primary-font;
    font-size: 14px;
    text-align: right;
    direction: rtl;
    white-space: pre-line;
}