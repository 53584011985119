@import "../../../src/theme/spacing.scss";

.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: var(--medium2-spacing);
    margin-top: var(--medium2-spacing);
}

.skeleton {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    animation: skeleton-loading 1.5s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(0, 0%, 78%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.skeleton-field {
    width: 100%;
    height: 60px;
    border-radius: 10px;
}