@import "../../theme/fonts.scss";
@import "../../theme/spacing.scss";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    min-width: 100%;
    min-height: 100%;
}

.title {
    width: 100%;
    font-family: $secondary-font;
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    text-align: center;
    color: #2d2d2d;
    overflow: hidden;
    padding-bottom: 8px;
}

.subtitleMessage {
    font-family: $primary-font;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #2d2d2d;
}

.imageStyle {
    width: 51px;
    padding-bottom: var(--medium-spacing);
}

@media screen and (max-width: 700px) {
    .container {
        min-height: 80vh;
    }

}