@import "../../theme/spacing.scss";
@import "../../theme/colors.scss";
@import "../../theme/fonts.scss";

.container {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: row;
    border-radius: 10px;
    padding: var(--medium-spacing);
    gap: var(--medium-spacing);
}

.text {
    font-family: $secondary-font;
    font-size: 12px;
    font-weight: $font-weight-semi-bold;
}