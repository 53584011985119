@import "../../theme/spacing.scss";
@import "../../theme/fonts.scss";

@mixin cardLayout {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 4px 14px 0px #9696961a;
  min-width: 100%;
  max-width: 100%;

  &:hover {
    cursor: pointer;
  }
}

.cardLayoutNotActive {
  @include cardLayout();
  border: 1px solid #eaeaea;
}

.cardLayoutActive {
  @include cardLayout();
  border: 1px solid #7595f5;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  gap: var(--medium-spacing);
  padding: 15px 8px 15px 8px;
}

.icon {
  align-self: flex-start;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  gap: var(--medium-spacing);
  justify-content: center;
}

.descriptionContainer {
  display: flex;
  flex-direction: row;
  gap: var(--medium-spacing);
  align-items: center;
}

.title {
  font-family: $primary-font;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: start;
  color: #191c1f;
  padding-top: var(--small-spacing);
}

.description {
  font-family: $primary-font;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: start;
  color: #2d2d2d;
}

.bullet {
  min-width: 6px;
  min-height: 6px;
  opacity: 0.1;
  background-color: #2b2a2a;
  border-radius: 10px;
}