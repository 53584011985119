@import "../../../src/theme/colors.scss";
@import "../../../src/theme/spacing.scss";
@import "../../../src/theme/fonts.scss";

.container {
  display: flex;
  flex-direction: column;
  margin-left: var(--large-spacing);
  justify-content: center;
  align-items: center;
}

.dropdownButton {
  font-family: $primary-font;
  position: relative;
  color: $primary-color;
  cursor: pointer;
  border-radius: var(--medium-spacing);
  font-weight: 600;
  direction: rtl;
  outline: none;
  background-color: transparent;
  text-align: end;

  &:focus {
    border-color: $blue-color;
  }
}

.innerContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--small-spacing);
  align-items: center;
}

.dropdownValue {
  margin: 0;
  margin-right: var(--medium-spacing);
  color: $primary-color;
}

.arrowIcon {
  margin: 0;
  cursor: pointer;

  [dir="rtl"] & {
    rotate: 180deg;
  }

}