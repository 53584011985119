@import "../../../theme/fonts.scss";

.primaryButton {
  width: 100%;
  padding: 15px;
  background-color: #2b2a2a;
  justify-content: center;
  align-items: center;
  font-family: $primary-font;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  border: 0;
  border-radius: 100px;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
  }

  &:disabled:hover {
    opacity: 0.5;
    cursor: unset;
  }
}