@import "../../../src/theme/colors.scss";
@import "../../../src/theme/spacing.scss";
@import "../../../src/theme/fonts.scss";

.tooltipContainer {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltipContainer img {
  width: 14px;
  height: 14px;
}

.tooltipContainer .tooltipText {
  visibility: hidden;
  max-width: 160px;
  width: 160px;
  background-color: rgba(45, 45, 45, 0.8);
  color: #fff;
  border-radius: 10px;
  padding: 12px;
  position: absolute;
  font-size: 12px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  word-wrap: break-word;
  text-align: start;
  top: 50%;
  left: -100%;
  transform: translate(-100%, -50%);

  [dir="rtl"] & {
    left: 0;
    transform: translate(20%, -50%);
    text-align: end;
  }
}

.tooltipContainer:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}

.tooltipContainer .tooltipText::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent rgba(45, 45, 45, 0.8);

  [dir="rtl"] & {
    right: 100%;
    left: auto;
    border-color: transparent rgba(45, 45, 45, 0.8) transparent transparent;
  }
}