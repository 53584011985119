@import "../../../src/theme/spacing.scss";
@import "../../../src/theme/fonts.scss";
@import "../../../src/theme/colors.scss";

.container {
  margin-top: var(--large-spacing);
  width: 100%;
}
.bigContainer {
  margin-top:var(--large-spacing);
}
.largeContainer {
  margin-top:var(--double-extra-large-spacing)
}

.otpFooterStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.textStyle {
  margin-top: var(--medium-spacing);
}

.disabledText {
  font-family: $primary-font;
  font-weight: $font-weight-medium;
  font-size: 14px;
  color: $disabled-color-v2;
  cursor: "default";
  pointer-events: none;
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-color: $disabled-color-v2;
}

.enabledText {
  font-family: $primary-font;
  font-weight: $font-weight-medium;
  font-size: 14px;
  color: $primary-color;
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-color: $primary-color;
}

.successMessage {
  font-family: $primary-font;
  color: $approved-color;
}

.errorText {
  font-family: $primary-font;
  color: $error-color;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.loader {
  border: 2px solid transparent;
  border-top: 2px solid #04cbab;
  border-bottom: 2px solid #f3f3f3;
  border-left: 2px solid #04cbab;
  border-right: 2px solid #04cbab;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: loader 1s linear infinite;
}

.phoneNumberDirection{
  direction: ltr;
  unicode-bidi: bidi-override;
}